import React from "react";
import { Divider } from "primereact/divider";
import { Image } from "primereact/image";

import {
	ImFlag,
	ImAccessibility,
	ImSun,
	ImNotification,
	ImEarth,
} from "react-icons/im";

import InfoPhoto1 from "../images/Info/sunset1.jpg";
import InfoPhoto2 from "../images/Info/mountainSunset.JPG";
import InfoPhoto3 from "../images/Info/CraneNice.JPG";
import InfoPhoto4 from "../images/Info/Gondo.png";
import InfoPhoto5 from "../images/Info/NiceView.JPG";

export const Info = () => {
	return (
		<>
			<div className="grid">
				<div className="md:col-6 sm:col-12 p-6 align-content-center align-items-center">
					<Image
						imageStyle={{
							maxHeight: "500px",
							height: "100%",
							width: "100%",
							maxWidth: "100%",
						}}
						src={InfoPhoto1}
						alt="Image"
					/>
				</div>
				<div className="md:col-6 sm:col-12 p-6 align-content-center align-items-center">
					<Divider align="center">
						<div className="inline-flex align-content-center align-items-center md:text-2xl sm:text-xl">
							<ImFlag className="mr-2" />
							<b className="specialFont md:text-4xl sm:text-2xl"> CAMP </b>
							<ImFlag className="ml-2" />
						</div>
					</Divider>
					<span className="text-700 md:text-xl sm:text-l line-height-3">
						Karimojong Overland Safaris have developed a delightful little camp
						near the Uganda Wildlife Authority station at Moruajore. The camp is
						permanent tented in design with
						<strong> 5 guest chalets </strong> on raised wooden decks. The
						chalets have <strong> en suite bathroom facilities </strong> with
						hot and cold water. Situated centrally within the reserve, most of
						the hunting takes place within two hour's drive from camp.
					</span>
				</div>

				<div className="md:col-6 sm:col-12 p-6 align-content-center align-items-center">
					<Divider align="center">
						<div className="inline-flex align-items-center md:text-2xl sm:text-xl">
							<ImAccessibility className="mr-2" />
							<b className="specialFont md:text-4xl sm:text-2xl"> ACCESS </b>
							<ImAccessibility className="ml-2" />
						</div>
					</Divider>
					<span className="text-700 md:text-xl sm:text-l line-height-3">
						Uganda is easily accessible via several air routes;
						<br />
						<strong> From Europe</strong> there are several options: KLM,
						Brussels Air, Ethiopian Airlines, Emirates, etc.
						<br />
						<strong> From the USA</strong>, Delta/KLM via Amsterdam, or
						Ethiopian Airlines via Addis Ababa, or alternatively Qatar or
						Turkish Airlines.
						<br />
						<strong> Air arrival</strong> is through the international airport
						at Entebbe, an hour drive from Kampala, Uganda's main city.
						Depending on arrival and departure time, it is often necessary to
						overnight in either Entebbe or Kampala.
					</span>
				</div>
				<div className="md:col-6 sm:col-12 p-6 align-content-center align-items-center">
					<Image
						imageStyle={{
							maxHeight: "500px",
							height: "100%",
							width: "100%",
							maxWidth: "100%",
						}}
						src={InfoPhoto2}
						alt="Image"
					/>
				</div>

				<div className="md:col-6 sm:col-12 p-6 align-content-center align-items-center">
					<Image
						imageStyle={{
							maxHeight: "500px",
							height: "100%",
							width: "100%",
							maxWidth: "100%",
						}}
						src={InfoPhoto3}
						alt="Image"
					/>
				</div>

				<div className="md:col-6 sm:col-12 p-6 align-content-center align-items-center">
					<Divider align="center">
						<div className="inline-flex align-items-center md:text-2xl sm:text-xl">
							<ImSun className="mr-2" />
							<b className="specialFont md:text-4xl sm:text-2xl"> CLIMATE </b>
							<ImSun className="ml-2" />
						</div>
					</Divider>
					<span className="text-700 md:text-xl sm:text-l line-height-3">
						Uganda does not have defined hot and cold seasons, being on the
						equator. There are two wet seasons - April to July and September to
						November. <br />
						<strong>
							Temperatures between January and May, the prime hunting season,
							range between minimums of 20&deg;C and maximums of 38&deg;C.
						</strong>
					</span>
				</div>

				<div className="md:col-6 sm:col-12 p-6 align-content-center align-items-center">
					<Divider align="center">
						<div className="inline-flex align-items-center md:text-2xl sm:text-xl">
							<ImNotification className="mr-2" />
							<b className="specialFont md:text-4xl sm:text-2xl">
								VISAS & VACCINES
							</b>
							<ImNotification className="ml-2" />
						</div>
					</Divider>
					<span className="text-700 md:text-xl sm:text-l line-height-3">
						All visitors under the age of 65 must have a yellow fever
						vaccination certificate.
						<br />
						<strong> Visas </strong> must be applied for online at
						<a
							href="https://visas.immigration.go.ug/"
							target="_blank"
							rel="noopener noreferrer"
						>
							<strong> Uganda E-Immigration System </strong>
						</a>
					</span>
				</div>
				<div className="md:col-6 sm:col-12 p-6 align-content-center align-items-center">
					<Image
						imageStyle={{
							maxHeight: "500px",
							height: "100%",
							width: "100%",
							maxWidth: "100%",
						}}
						src={InfoPhoto4}
						alt="Image"
					/>
				</div>

				<div className="md:col-6 sm:col-12 p-6 align-content-center align-items-center">
					<Image
						imageStyle={{
							maxHeight: "500px",
							height: "100%",
							width: "100%",
							maxWidth: "100%",
						}}
						src={InfoPhoto5}
						alt="Image"
					/>
				</div>

				<div className="md:col-6 sm:col-12 p-6 align-content-center align-items-center">
					<Divider align="center">
						<div className="inline-flex align-items-center md:text-2xl sm:text-xl">
							<ImEarth className="mr-2" />
							<b className="specialFont md:text-4xl sm:text-2xl">
								COMMUNICATIONS
							</b>
							<ImEarth className="ml-2" />
						</div>
					</Divider>
					<span className="text-700 md:text-xl sm:text-l line-height-3">
						<strong>Roads - </strong> PiaNupe is an 8 to 9 hour drive from
						Entebbe, the international airport, depending on traffic around
						Kampala.
						<br />
						<strong>Charter air - </strong>To be recommended given the long road
						travel times. Rates are comparable to most East African Countries.
						<br />
						We also have a <strong>private airfield</strong> 10 minutes from
						camp.
						<br />
						<strong>Mobile Phone and Internet Service - </strong> Overall country
						wide coverage is good, via several network providers, with either 3G
						or 4G internet.
						<br />
						<strong>We do provide WiFi in the common area of the camp</strong>.
					</span>
				</div>
			</div>
		</>
	);
};

export default Info;
