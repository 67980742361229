import React from "react";
import { Image } from "primereact/image";

import heroPic from "../images/Info/CheetahNice.JPG";

export const Home = () => {
	return (
		<div className="grid text-800">
			<div className="xl:col-6 lg:col-12 p-6 text-center xl:text-left flex align-items-center ">
				<section>
					<span className="block md:text-7xl sm:text-5xl font-bold mb-1">
						PiaNupe Wildlife Reserve, Karamoja
					</span>
					<div className="md:text-5xl sm:text-4xl text-primary font-bold mb-3">
						Walter Dalrymple Maitland Bell...
					</div>
					<p className="mt-0 md:text-xl sm:text-m line-height-3">
						or "Karamojo" Bell, is widely regarded as the greatest hunter of
						them all. His travels and hunting exploits, particularly amongst
						elephant, in the Karamoja region of Uganda in the early 1900's are
						well documented. <br /> <br /> Karimojong Overland Safaris are
						pleased to announce that responsible hunting in the Karamoja is a
						reality once again, after a break of some 45 years. The concession
						is the PiaNupe Wildlife Reserve in southern Karamoja, 2,500 km
						<sup>2</sup> (620,000 acres) in the shadow of one of East Africa's
						great mountains, Mt. Elgon. <br /> Karimojong Overland Safaris have
						exclusive management rights for 25 years. After 3 trial seasons
						where populations of game were assessed and evaluated, with limited
						hunting, 2014 was the first year a full season took place. The
						quality of trophies, the diversity of species available and history
						of the region combine to give the PiaNupe the potential to become a
						premier destination for all serious trophy hunters of African game
						once again. <br /> Karimojong Overland Safaris are committed long
						term to the development of the southern Karamoja region in general
						and PiaNupe Wildlife Reserve specifically, through strong community
						participation and sustainable utilization of all natural resources
						within the region.
					</p>
				</section>
			</div>
			<div className="xl:col-6 lg:hidden sm:hidden xl:flex overflow-hidden">
				<Image
					imageStyle={{
						height: "600px",
						maxHeight: "600px",
						maxWidth: "100%",
						width: "100%",
						clipPath: "polygon(8% 0, 100% 0%, 100% 100%, 0 100%)",
					}}
					src={heroPic}
					alt="Image"
				/>
			</div>
		</div>
	);
};

export default Home;
