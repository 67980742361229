import React, { useEffect, useState, useCallback } from "react";
import { GMap } from "primereact/gmap";

/* GoogleMaps.js */

export const KOSMap = () => {
	const [googleMapsReady, setGoogleMapsReady] = useState(false);
	const [overlays, setOverlays] = useState(null);
	const options = {
		center: { lat: 1.1118737513389554, lng: 32.6029075428268 },
		zoom: 7,
	};

	const loadGoogleMaps = async (callback) => {
		const existingScript = document.getElementById("googleMaps");

		if (!existingScript) {
			const script = document.createElement("script");
			script.src =
				"https://maps.google.com/maps/api/js?key=AIzaSyAK4tOmN_vyKfDc694x40uSPZLpvcCuKq4";
			script.id = "googleMaps";
			// script.async = true;
			// script.defer = true;
			document.body.appendChild(script);

			script.onload = () => {
				if (callback) callback();
			};
		}

		if (existingScript && callback) callback();
	};

	// const removeGoogleMaps = async () => {
	// 	const mapScript = document.getElementById("googleMaps");

	// 	if (mapScript) {
	// 		mapScript.parentNode.removeChild(mapScript);

	// 		const head = document.getElementsByTagName("head")[0];
	// 		const scripts = head.getElementsByTagName("script");
	// 		for (let i = 0; i < scripts.length; i++) {
	// 			let script = scripts[i];
	// 			let src = script.src;

	// 			if (src.startsWith("https://maps.google.com/maps")) {
	// 				head.removeChild(script);
	// 			}
	// 		}
	// 	}
	// };

	const onMapReady = (event) => {
		const newMarkers = [
			// eslint-disable-next-line no-undef
			new google.maps.Marker({
				position: { lat: 1.691306370324063, lng: 34.5828522173967 },
				title: "KOS Camp",
			}),
		];
		setOverlays(newMarkers);
	};

	const initMap = useCallback(() => {
		loadGoogleMaps(() => {
			setGoogleMapsReady(true);
		});

		// return () => {
		// 	removeGoogleMaps();
		// };
	}, []);

	useEffect(() => {
		initMap();
	}, [initMap]);

	return (
		googleMapsReady && (
			<div className="card">
				<GMap
					overlays={overlays}
					options={options}
					style={{ width: "100%", minHeight: "450px" }}
					onMapReady={() => onMapReady()}
					// onMapClick={onMapClick}
					// onOverlayClick={onOverlayClick}
					// onOverlayDragEnd={handleDragEnd}
				/>
			</div>
		)
	);
};

export default KOSMap;
