import PrimeReact from 'primereact/api';
import React, { useState, useCallback, useRef, useEffect } from 'react';
import './App.css';
import "./primeflex/primeflex.min.css";
import "primereact/resources/themes/arya-orange/theme.css"; //theme
import "primeicons/primeicons.css"; //icons
import "primereact/resources/primereact.min.css"; //core css
import styled from "styled-components";
// import { Button } from 'primereact/button';
// import { TabMenu } from 'primereact/tabmenu';
import { Menubar } from 'primereact/menubar';
import { SpeedDial } from 'primereact/speeddial';
import Home from './components/Home.component';
import Info from './components/Info.component';
import Hunting from './components/Hunting.component';
import Gallery from './components/Gallery.component';
import Footer from './components/Footer.component';

import {
  KOS_LOGO,
  // Video_mp4,
  // Video_mp4_2,
  // Video_avi,
  // Video_mp4_3,
  Video_poster,
  SPEED_DIAL_ITEMS
} from './consts';

function App() {
  PrimeReact.ripple = true;
  const infoRef = useRef(null);
  const huntingRef = useRef(null);
  const galleryRef = useRef(null);
  const moreInfoRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    document.addEventListener('contextmenu', (e) => e.preventDefault());
  }, [])


  const handleHome = useCallback(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const handleInformation = useCallback(() => {
    infoRef.current.scrollIntoView({ behavior: 'smooth' });
  }, []);

  const handleHunting = useCallback(() => {
    huntingRef.current.scrollIntoView({ behavior: 'smooth' });
  }, []);

  const handleGallery = useCallback(() => {
    galleryRef.current.scrollIntoView({ behavior: 'smooth' });
  }, []);

  const handleMoreInfo = useCallback(() => {
    moreInfoRef.current.scrollIntoView({ behavior: 'smooth' });
  }, []);

  const scrollDown = () => {
    window.scrollTo({ top: 900, left: 0, behavior: "smooth" });
  };

  const MENU_ITEMS = [
    // { label: 'Home', icon: 'pi pi-fw pi-home', activeIndex: 0, command: () => { handleHome() } },
    { label: 'Information', icon: 'pi pi-fw pi-calendar', activeIndex: 1, command: () => { handleInformation() } },
    { label: 'Hunting', icon: 'pi pi-fw pi-pencil', activeIndex: 2, command: () => { handleHunting() } },
    { label: 'Photos', icon: 'pi pi-fw pi-file', activeIndex: 3, command: () => { handleGallery() } },
    { label: 'More Info', icon: 'pi pi-fw pi-cog', activeIndex: 4, command: () => { handleMoreInfo() } }
  ];

  const VideoBg = styled.video`
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
  `;

  const ViewPortHeader = styled.header`
    position: relative;
	  height: 100%;
    width: 100%
	  text-align: center;
	  display: flex;
	  align-items: center;
	  justify-content: center;
  `;

  const HeroHeader = styled.h1`
	  color: white;
	  text-transform:none;
    align-items: center;
    justify-content: center;
	  /* letter-spacing: 0.8vw; */
    text-shadow: 3px 3px black;
	  line-height: 1.2;
    padding-top: 8vh;
	  font-size: 4vw;
	  text-align: center;
  `;

  const HeroSmallSpan = styled.span`
    transform: translate(0%, 140%);
    font-size: 2.5vw;
    display: block;
  `;
  const HeroBigSpan = styled.span`
    display: block;
    font-family: "Charm";
    font-size: 12vw;
    letter-spacing: -1.1vw;
  `;

  const GoDown = styled.div`
    display: block;
    align-items: center;
    justify-content: center;
    text-align: center; 
  `;

  return (

    <>
      <div className="grid">

        <VideoBg
          muted
          autoPlay
          loop
          playsInline
          preload
          poster={Video_poster}
        >
          <source src="/Video/WebsiteBGVideo.mp4" type="video/mp4"></source>
          {/* <source src={Video_mp4_2} type="video/mp4"></source>
          <source src={Video_mp4_3} type="video/ogg"></source>
          <source src={Video_avi} type="video/avi"></source> */}
          Video not supported.
        </VideoBg>
        <div className="col-12 align-content-center">
          <Menubar model={MENU_ITEMS} activeIndex={activeIndex} style={{ alignItems: 'center', justifyContent: 'center' }} onTabChange={(e) => setActiveIndex(e.index)} />
          <div className="speeddial-circle-demo">
            <SpeedDial model={SPEED_DIAL_ITEMS} radius={100} direction="up-left" transitionDelay={80} showIcon="pi pi-bars" hideIcon="pi pi-times" type="quarter-circle" />
          </div>
        </div>

        <div onClick={handleHome}><img id="logo2" src={KOS_LOGO} alt="logo" /></div>

        <ViewPortHeader>
          <div className="h-screen w-screen align-items-center align-content-center  top-50 left-50">
            <HeroHeader>
              <HeroSmallSpan>Experience</HeroSmallSpan>
              <HeroBigSpan>Karamoja</HeroBigSpan>
            </HeroHeader>
            <GoDown>

              {/* <Button className="text-5xl" label="SEE MORE" iconPos="bottom" onClick={scrollDown} /> */}
              <i className="pi pi-angle-down p-1 m-1 scalein animation-duration-2000 animation-iteration-infinite" style={{ 'fontSize': '3em' }} onClick={scrollDown}></i>
            </GoDown>

          </div>
        </ViewPortHeader>

        <main>

          <div className="md:col-10 md:col-offset-1 sm:col-12 align-content-center">
            <Home />
          </div>

          <div className="md:col-10 md:col-offset-1 sm:col-12 align-content-center">
            <h2 className="text-center md:text-5xl sm:text-3xl specialFont" ref={infoRef}>GENERAL INFORMATION</h2>
            <Info />
          </div>

          <div className="md:col-10 md:col-offset-1 sm:col-12 align-content-center">
            <h2 className="text-center md:text-5xl sm:text-3xl specialFont" ref={huntingRef}>HUNTING INFORMATION</h2>
            <Hunting />
          </div>

          <div className="md:col-10 md:col-offset-1 sm:col-12 align-content-center">
            <h2 className="text-center md:text-5xl sm:text-3xl specialFont" ref={galleryRef}>PHOTOS</h2>
            <Gallery />
          </div>

          {/* FOOTER */}
          <div ref={moreInfoRef}>
            <Footer />
          </div>
        </main>
      </div>
    </>
  );
}

export default App;