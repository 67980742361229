import React from "react";
import { Image } from "primereact/image";
import { Card } from "primereact/card";
import { Divider } from "primereact/divider";

import { CAMP_PHOTOS, GALLERY_PHOTOS } from "../consts";

export const Gallery = () => {
	return (
		<Card>
			<div className="grid">
				<Divider align="center">
					<span className="p-tag">CAMP</span>
				</Divider>
				{CAMP_PHOTOS.map((pic, index) => (
					<div className="md:col-3">
						<Image
							src={pic.image}
							key={`CP-${index}`}
							preview
							imageStyle={{
								maxHeight: "500px",
								height: "100%",
								maxWidth: "100%",
								width: "100%",
							}}
							template={pic.caption}
							alt={pic.alt}
						/>
					</div>
				))}
				<Divider align="center">
					<span className="p-tag">SPECIES</span>
				</Divider>
				{GALLERY_PHOTOS.map((pic, index) => (
					<div className="md:col-3">
						<Image
							src={pic.image}
							key={`HP-${index}`}
							preview
							imageStyle={{
								maxHeight: "500px",
								height: "100%",
								maxWidth: "100%",
								width: "100%",
							}}
							template={pic.caption}
							alt={pic.alt}
						/>
					</div>
				))}
			</div>
		</Card>
	);
};

export default Gallery;
