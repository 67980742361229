import React from "react";
import { Divider } from "primereact/divider";
import { Image } from "primereact/image";

import { ImTarget, ImShield } from "react-icons/im";
import { GiHuntingHorn, GiAfrica } from "react-icons/gi";
import { FaBinoculars } from "react-icons/fa";

import HuntingPhoto1 from "../images/Hunting/buffSunset.jpg";
import HuntingPhoto2 from "../images/Hunting/eland_herd.png";
import HuntingPhoto3 from "../images/Hunting/sunsetHartebeest.png";
import HuntingPhoto4 from "../images/Hunting/topiHerd.jpg";
import HuntingPhoto5 from "../images/Hunting/Roan.JPG";

export const Hunting = () => {
	return (
		<>
			<div className="grid">
				<div className="md:col-6 sm:col-12 p-6 align-content-center align-items-center">
					<Image
						imageStyle={{
							maxHeight: "700px",
							height: "100%",
							width: "100%",
							maxWidth: "100%",
						}}
						src={HuntingPhoto1}
						alt="Image"
					/>
				</div>
				<div className="md:col-6 sm:col-12 p-6 align-content-center align-items-center">
					<Divider align="center">
						<div className="inline-flex align-content-center align-items-center md:text-2xl sm:text-xl">
							<ImTarget className="mr-2" />
							<b className="specialFont md:text-4xl sm:text-2xl"> FIREARMS </b>
							<ImTarget className="ml-2" />
						</div>
					</Divider>
					<span className="text-700 md:text-xl sm:text-l line-height-3">
						A temporary firearm import permit is applied for and issued before
						arrival in Uganda. We need an official certificate of ownership,
						passport copy, full flight details and information of the firearm to
						apply for the permit. This information is also needed to apply for a
						hunting permit.
						<br />
						<strong>Recommended calibers</strong> for all species OTHER than
						buffalo are any of the .300s and 7mms. For buffalo the minimum legal
						caliber is 375.
						<br />
						<strong>
							{/* PLEASE bring Barnes TSX, Swift A Frame or Trophy Bear Claw
							ammunition. */}
							Barnes TSX, Swift A Frame or Trophy Bear Claw ammunition are
							preferred, tried and tested in Africa
						</strong>
					</span>
				</div>

				<div className="md:col-6 sm:col-12 p-6 align-content-center align-items-center">
					<Divider align="center">
						<div className="inline-flex align-items-center md:text-2xl sm:text-xl">
							<GiHuntingHorn className="mr-2" />
							<b className="specialFont md:text-4xl sm:text-2xl"> HUNTING </b>
							<GiHuntingHorn className="ml-2" />
						</div>
					</Divider>
					<span className="text-700 md:text-xl sm:text-l line-height-3">
						PiaNupe is mostly flat, open country. Expect shots on plains game
						from a minimum of 100 yds out to 300 yds. The country is quite rough
						underfoot, particularly where burning has taken place which occurs
						in most safari areas of Uganda. Buffalo hunting is variable with
						some open plains areas where shots may be out to 150 yds, but more
						commonly expect heavier country and shots from 50 yds to 100 yds.
					</span>
				</div>
				<div className="md:col-6 sm:col-12 p-6 align-content-center align-items-center">
					<Image
						imageStyle={{
							maxHeight: "700px",
							height: "100%",
							width: "100%",
							maxWidth: "100%",
						}}
						src={HuntingPhoto2}
						alt="Image"
					/>
				</div>

				<div className="md:col-6 sm:col-12 p-6 align-content-center align-items-center">
					<Image
						imageStyle={{
							maxHeight: "700px",
							height: "100%",
							width: "100%",
							maxWidth: "100%",
						}}
						src={HuntingPhoto3}
						alt="Image"
					/>
					<div className="sm:hidden lg:block mt-7 pt-5">
						<span className="text-700 md:text-xl sm:text-l line-height-3">
							Our safaris often take us to other areas where species not
							available in ours may be hunted. This includes Uganda Kob and East
							African Impala.
						</span>
					</div>
				</div>
				<div className="md:col-6 sm:col-12 p-6 align-content-center align-items-center">
					<Divider align="center">
						<div className="inline-flex align-items-center md:text-2xl sm:text-xl">
							<FaBinoculars className="mr-2" />
							<b className="specialFont md:text-4xl sm:text-2xl"> SPECIES </b>
							<FaBinoculars className="ml-2" />
						</div>
					</Divider>
					<span className="text-700 md:text-xl sm:text-l line-height-3">
						<ul>
							<li>Sudan Roan</li>
							<li>Topi</li>
							<li>Nile Buffalo</li>
							<li>Jackson's Hartebeest</li>
							<li>East African Sitatunga</li>
							<li>East African Defassa Waterbuck</li>
							<li>East African (Patterson's) Eland</li>
							<li>Eastern Bohor Reedbuck</li>
							<li>Chanler's Mountain Reedbuck</li>
							<li>Sudan Oribi</li>
							<li>Guenther's Dik Dik</li>
							<li>East African Bush Duiker</li>
							<li>Nile Bushbuck</li>
							<li>East African Bushbuck</li>
							<li>Warthog</li>
							<li>Leopard</li>
							<li>Olive Baboon</li>
						</ul>
					</span>
					<span className="text-700 md:text-xl sm:text-l line-height-3">
						Other species in the reserve but which are not on license, include
						Rothschild's Giraffe, Spotted Hyena, Uganda Kob, Klipspringer,
						Cheetah, Vervet and Patas Monkeys, and small cats.
					</span>
					<br />
					<span className="sm:block lg:hidden text-700 md:text-xl sm:text-l line-height-3">
						Our safaris often take us to other areas where species not available
						in ours may be hunted. This includes Uganda Kob and East African
						Impala.
					</span>
				</div>

				<div className="md:col-6 sm:col-12 p-6 align-content-center align-items-center">
					<Divider align="center">
						<div className="inline-flex align-items-center md:text-2xl sm:text-xl">
							<ImShield className="mr-2" />
							<b className="specialFont md:text-4xl sm:text-2xl"> CONTROL </b>
							<ImShield className="ml-2" />
						</div>
					</Divider>
					<span className="text-700 md:text-xl sm:text-l line-height-3">
						Hunting is controlled in Uganda by the Uganda Wildlife Authority
						under the guidance of the Ministry of Tourism, Wildlife and
						Antiquities. As such, all hunt outfitters in Uganda are legally
						required to follow all rules and regulations pertaining to the
						consumptive tourism industry. The Wildlife Conservation & Management
						Association (Uganda) is the representative organ of all Uganda
						outfitters. This association is a member of the Operators and
						Professional Hunters Associations of Africa (OPHAA) and is signatory
						to their Code of Conduct
					</span>
				</div>
				<div className="md:col-6 sm:col-12 p-6 align-content-center align-items-center">
					<Image
						imageStyle={{
							maxHeight: "700px",
							height: "100%",
							width: "100%",
							maxWidth: "100%",
						}}
						src={HuntingPhoto4}
						alt="Image"
					/>
				</div>

				<div className="md:col-6 sm:col-12 p-6 align-content-center align-items-center">
					<Image
						imageStyle={{
							maxHeight: "700px",
							height: "100%",
							width: "100%",
							maxWidth: "100%",
						}}
						src={HuntingPhoto5}
						alt="Image"
					/>
				</div>
				<div className="md:col-6 sm:col-12 p-6 align-content-center align-items-center">
					<Divider align="center">
						<div className="inline-flex align-content-center align-items-center md:text-2xl sm:text-xl">
							<GiAfrica className="mr-2" />
							<b className="specialFont md:text-4xl sm:text-2xl"> ETHOS </b>
							<GiAfrica className="ml-2" />
						</div>
					</Divider>
					<span className="text-700 md:text-xl sm:text-l line-height-3">
						<strong>
							KOS Uganda is committed to providing a 'fair chase' hunting
							experience.
						</strong>
						<br />
						The 'fair chase' philosophy is generally described as the ethical,
						sportsmanlike, and lawful pursuit of free-ranging wild game animals,
						in a manner that does not give the hunter an improper advantage over
						the animal. As such, there is no assurance or certainty as to the
						success of your safari, where success is measured in terms of the
						number or species of animals taken. Success varies greatly from
						species to species, safari to safari, season to season and hunter to
						hunter. Unpredictable factors that play on success are many and
						include, but are not limited to, the time of season, the ability and
						fitness of the hunter, the availability of game food and water,
						climatic changes, human influences, drought conditions and old
						fashioned luck. We believe it is important that both our clients and
						professional hunters subscribe to this same 'fair chase' philosophy
						and are proud of the way in which hunts are conducted measuring the
						success of any safari by the quality and the thrill of the chase,
						the unique atmosphere of the African bush, and the people, animals,
						sights, smells and sounds that you encounter while in Uganda. You
						will need to be reasonably fit, capable of carrying your own firearm
						for extended periods and willing to walk to seek your intended
						trophy. You will be hunting aged male specimens only, and we ask
						that you accept the judgment of your professional hunter when it
						comes to trophy quality. We endeavour to provide you with the
						opportunity to shoot top quality trophies in a fair chase and
						sportsmanlike manner.
					</span>
				</div>
			</div>
		</>
	);
};

export default Hunting;
