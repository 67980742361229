import KOSLogo from "./images/KOS_Logo.png";
import CICLogo from "./images/CIC_Logo.png";
import UWALogo from "./images/UWA_Logo.png";
import DSCLogo from "./images/DSC_logo.png";
import SCILogo from "./images/SCI_Logo.png";
import OPHAALogo from "./images/OPHAA_logo.png";

// Gallery Camp Photos
import Camp1 from "./images/Gallery_C/lounge.jpg";
import Camp2 from "./images/Gallery_C/rooms.jpg";
import Camp3 from "./images/Gallery_C/tent.jpg";
import Camp4 from "./images/Gallery_C/tent2.jpg";

// Gallery View Photos
import View1 from "./images/Gallery_V/niceMountain.JPG";
import View2 from "./images/Gallery_V/View.JPG";
import View3 from "./images/Gallery_V/View2.JPG";
import View4 from "./images/Gallery_V/Trees.JPG";

// Gallery Hunting Photos
import Gallery1 from "./images/Gallery_H/buff.jpg";
import Gallery2 from "./images/Gallery_H/dagga_boi.jpg";
import Gallery3 from "./images/Gallery_H/twoBuffaloes.jpg";
import Gallery4 from "./images/Gallery_H/sudanOribi.jpg";
import Gallery5 from "./images/Gallery_H/EasternDefassa.png";
import Gallery6 from "./images/Gallery_H/eland.jpg";
import Gallery16 from "./images/Gallery_H/eland2.JPG";
import Gallery7 from "./images/Gallery_H/jHaretbeestLooking.jpg";
import Gallery8 from "./images/Gallery_H/kob.jpg";
import Gallery9 from "./images/Gallery_H/UgandaKob.png";
import Gallery10 from "./images/Gallery_H/oribi.jpg";
import Gallery11 from "./images/Gallery_H/sitatunga2.jpg";
import Gallery12 from "./images/Gallery_H/topiCloseUp.png";
// import Gallery13 from "./images/Gallery_H/waterbuckFemales.JPG";
import Gallery14 from "./images/Gallery_H/bushDuiker.JPG";
import Gallery15 from "./images/Gallery_H/roanMore.JPG";

import VideoPoster from "./images/Website BG Video_poster.jpg";

export const Video_mp4 = "/Video/WebsiteBGVideo.mp4";
export const Video_mp4_2 = "https://uph.blob.core.windows.net/uph-public/Website BG Video (1).mp4";
export const Video_avi = "https://uph.blob.core.windows.net/uph-public/Website BG Video_1.avi";
export const Video_mp4_3 = "https://uph.blob.core.windows.net/uph-public/Website BG Video.mp4";
export const KOS_LOGO = KOSLogo;
export const Video_poster = VideoPoster;

export const CAMP_PHOTOS = [
  { image: Camp1, caption: "Common Area", alt: "Common Area" },
  { image: Camp2, caption: "Interiors", alt: "Interiors" },
  { image: Camp3, caption: "Accommodation", alt: "Accommodation" },
  { image: Camp4, caption: "Accommodation", alt: "Accommodation" },
  { image: View1, caption: "Mountain", alt: "Mountain" },
  { image: View2, caption: "Mountain", alt: "Mountain" },
  { image: View3, caption: "Mountain", alt: "Mountain" },
  { image: View4, caption: "Trees", alt: "Trees" },
];

export const GALLERY_PHOTOS = [
  { image: Gallery1, caption: "Nile Buffalo", alt: "Nile Buffalo" },
  { image: Gallery2, caption: "Nile Buffalo", alt: "Nile Buffalo" },
  { image: Gallery3, caption: "Nile Buffaloes", alt: "Nile Buffaloes" },
  { image: Gallery4, caption: "Sudan Oribi", alt: "Sudan Oribi" },
  { image: Gallery10, caption: "Sudan Oribi", alt: "Sudan Oribi" },
  { image: Gallery5, caption: "Eastern Defassa Waterbuck", alt: "Eastern Defassa Waterbuck" },
  // { image: Gallery13, caption: "Eastern Defassa Waterbuck", alt: "Eastern Defassa Waterbuck" },
  { image: Gallery6, caption: "Patterson's Eland", alt: "Patterson's Eland" },
  { image: Gallery16, caption: "Patterson's Eland", alt: "Patterson's Eland" },
  { image: Gallery7, caption: "Jackson's Hartebeest", alt: "Jackson's Hartebeest" },
  { image: Gallery8, caption: "Uganda Kob", alt: "Uganda Kob" },
  { image: Gallery9, caption: "Uganda Kob", alt: "Uganda Kob" },
  { image: Gallery11, caption: "East African Sitatunga", alt: "East African Sitatunga" },
  { image: Gallery12, caption: "Topi", alt: "Topi" },
  { image: Gallery14, caption: "East African Bush Duiker", alt: "East African Bush Duiker" },
  { image: Gallery15, caption: "Sudan Roan", alt: "Sudan Roan" },
];

export const SPEED_DIAL_ITEMS = [
  {
    label: 'Youtube Videos',
    icon: 'pi pi-youtube',
    command: () => {
      window.open('https://drive.google.com/file/d/16ysGia9rrI_k4IbcghYNFeqmIhpa6Wu5/view?usp=share_link', '_blank')
    }
  },
  {
    label: 'Email',
    icon: 'pi pi-envelope',
    command: () => {
      window.location.href = "mailto:ugandaprohunts@gmail.com"
    }
  },
  {
    label: 'Phone',
    icon: 'pi pi-phone',
    command: () => {
      window.location.href = "tel:+256704989257"
    }
  },
  {
    label: 'Phone Alt',
    icon: 'pi pi-phone',
    command: () => {
      window.location.href = "tel:+44735970385"
    }
  },
  // {
  //   label: 'Upload',
  //   icon: 'pi pi-upload',
  //   command: () => {
  //     window.location.hash = "/fileupload"
  //   }
  // },
];

export const FOOTER_LOGOS = [
  { image: OPHAALogo, caption: "Operations and Professional Hunting Association Africa", alt: "Operations and Professional Hunting Association Africa" },
  { image: UWALogo, caption: "Uganda Wildlife Authority", alt: "Uganda Wildlife Authority" },
  // { image: KOS_LOGO, caption: "Karimojong Overland Safaris", alt: "KOS Logo" },
  { image: CICLogo, caption: "Karimojong Overland Safaris", alt: "CIC Logo" },
  { image: DSCLogo, caption: "Dallas Safari Club", alt: "Dallas Safari Club" },
  { image: SCILogo, caption: "Safari Club International", alt: "Safari Club International" },
];

const consts = {
  KOS_LOGO,
  Video_mp4,
  Video_mp4_2,
  Video_avi,
  Video_poster,
  Video_mp4_3, CAMP_PHOTOS, GALLERY_PHOTOS, FOOTER_LOGOS,
  SPEED_DIAL_ITEMS
};

export default consts;