import React from "react";
import { Image } from "primereact/image";
import { FOOTER_LOGOS } from "../consts";
import KOSMap from "./Map.component";

export const Footer = () => {
	return (
		<div className="mt-6 mb-0 pb-0 surface-0 text-700 text-center">
			<KOSMap />
			<div className="text-600 text-4xl font-bold mb-3 mt-1 specialFont">
				For further information, please contact us:
			</div>
			<div className="text-900 font-bold mt-2 p-2 mb-3">
				<div className="block">
					<div className="inline-flex p-2 mb-1">
						<a href="mailto:ugandaprohunts@gmail.com">
							<strong> ugandaprohunts@gmail.com </strong>
						</a>
					</div>
				</div>
				<div className="block">
					<div className="inline-flex p-2 mb-1">
						<a href="tel:+256704989257">
							<strong> +256 0704989257 </strong>
						</a>
						&nbsp; or &nbsp;
						<a href="tel:+447435970385">
							<strong> +44 07435970385 </strong>
						</a>
					</div>
				</div>
				<div className="block">
					<div className="">
						<p>Dallas Safari Club Convention 2025 Booth 1217</p>
					</div>
					<div className="pb-2">
						<p>Safari Club International Convention 2025 SCI Booth 735</p>
					</div>
				</div>
			</div>
			<div className="text-700 text-2xl md:mb-5 sm:mb-2">
				<div className="grid align-items-center">
					<div className="col-offset-1"></div>
					{FOOTER_LOGOS.map((pic, index) => (
						<div className="col-2">
							<Image
								src={pic.image}
								key={`HP-${index}`}
								imageStyle={{ maxHeight: "100%", maxWidth: "90%" }}
								alt={pic.alt}
							/>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default Footer;
